/* Body styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0;
}

/* Signup container styles */
.signup-container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* Signup form styles */
.signup-form {
  display: flex;
  flex-direction: column;
}

/* Label styles */
.signup-form label {
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left; /* Align labels to the left */
}

/* Input field styles */
.signup-form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Button styles */
.signup-form button {
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Error message styles */
.signup-form p {
  color: #ff0000;
  margin: 0;
  margin-bottom: 5px;
  font-size: 14px;
}

/* Add margin between rows */
.signup-form div {
  margin-bottom: 15px;
}
